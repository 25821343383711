
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

@import 'assets/icons/menu-icon.css';
@import 'assets/icons/qricon.css';

:root {
  --primary: #ff975c;
  --theme-secondary: #050027;
  --trans: rgba(0,0,0,0);
  --theme-tertiary: #84ceeb;
  --light-color: #EEF2FF;
  --hover-color: #f1f6ff;
  --primary-darker: #ff7d32;
  --theme-secondary-darker: #13162a;
  --primary-contrast-text-color: #13162a;
  --navigation_color: #a9afcd;
  --navigation_selected_color: #e8e9ee;
  --menu_color: #1c2138;
  --menu_color-darker: #1c2138;

  --light-warning: #FFF4DE;
  --light-danger: #FFE2E5;
  --light-success: #e7fff3;
  --light-info: #EEE5FF;
  --light: #f2f2f2;
  --info: #5255AA;
  --danger: #E41A36;

  --bs-success: #34C594;

  --light-button-color: #b0b8c5;
  --light-button-text-color: #6B7280;
}

$my-custom-typography-config: mat.define-typography-config(
  $headline:  mat.define-typography-level(26px, 29.4px, 700, 'Poppins', -0.2px),
  $title: mat.define-typography-level(22px, 24.4px, 700, 'Poppins', -0.2px),
  $subheading-2:  mat.define-typography-level(20px, 22.4px, 600, 'Poppins'),
  $subheading-1:  mat.define-typography-level(18px, 19.4px, 600, 'Poppins'),
  $body-1:  mat.define-typography-level(16px, 22.4px, 400, 'Poppins', 0),
);

.btn span{
  font-size: 16px !important;
  font-weight: 700 !important;
}

.swiper-button-next:after, .swiper-rtl .swiper-button-prev:after{
  color: white;
}

.swiper-button-prev:after, .swiper-rtl .swiper-button-next:after{
  color: white;
}

.header.header-dark{
  background-color: var(--menu_color) !important;
}

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!

@include mat.core($my-custom-typography-config);

.text-contrast{
  color: var(--primary-contrast-text-color);
}

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$demo1-primary: mat.define-palette(mat.$indigo-palette);
$demo1-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$demo1-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$demo1-theme: mat.define-light-theme((
  color: (
    primary: $demo1-primary,
    accent: $demo1-accent,
    warn: $demo1-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($demo1-theme);

/* You can add global styles to this file, and also import other style files */
@import "./assets/sass/style";
@import "./custom";

// Replace above style with this SCSS file to enable dark mode styles
//@import "./assets/sass/style.dark";

html, body { height: 100%; }
body { margin: 0; }

html, body { height: 100%; }
body { margin: 0; font-family: 'Poppins', "Helvetica Neue", sans-serif; }


.custom-logo{
  max-width: 170px;
}

.breadcrumb-bar{

  background-color: white;
  padding: 10px;
  border: 1px solid #ddd;
  margin-bottom: 1rem;

  nz-breadcrumb-item{
    font-size:12px;
    text-transform: uppercase;
    font-weight: 500;

    strong{
      font-weight: 800;
    }
  }

}

.single-tab:focus-visible, .single-tab {
  outline: none!important;
}

.cursor-outline{
  cursor: pointer;
}

.password-container{
  position: relative;

  .eye-icon{
    cursor: pointer;
    position: absolute;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.4;

    &:hover{
      opacity: 1;
    }

    &.shift-right{
      right: 35px;
    }

    i{
      font-size: 20px;
    }
  }

}


.aside-light .menu .menu-title{

  color: var(--navigation_color) !important;

}

.aside.aside-light{
  background: -webkit-linear-gradient(to bottom, var(--menu_color), var(--menu_color-darker));
  background: linear-gradient(to bottom, var(--menu_color), var(--menu_color-darker));
}

.tabset{
  *{
    outline:none;
  }
}

.modal-enterprise{
  background-color: var(--menu_color-darker);
  .ant-modal-body, .ant-modal-footer{
    background-color: var(--menu_color-darker);
  }
}




.dashboard-section{

  margin-top: 45px;

  .dashboard-section-header{
    display: flex;
    margin-bottom:25px;
    align-items: center;

    &.large-gap{
      margin-bottom: 25px;
    }

    &.small-gap{
      margin-bottom: 10px;
    }

    h2{
      font-weight: 700;
      font-size: 22px;
      margin: 0;
    }
  }

  .dashboard-card{
    display: flex;
    padding: 25px;
    background-color: #f5f5f5;
    border-radius: 18px;
    height: 100%;

    &.large-card{
      padding: 40px;
    }

    .icon-stat{
      width: 75px !important;
      height: 75px;
      margin-right: 18px;
      border-radius: 18px;
      background-color: var(--theme-secondary);
      display: flex;
      justify-content: center;
      align-items: center;
      i{
        color: white;
        font-size: 42px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .card-data{
      flex:1;

      h5.stat-title{
        font-weight: 600;
        font-size: 18px;
        margin:0;
        color: var(--theme-secondary);
      }

      p.stat-description{
        font-weight: 500;
        font-size: 14px;
        margin: 4px 0 8px 0;
        color: #8A8FA9;
      }

      h2{
        font-size: 28px;
        font-weight: 600;
        margin: 0;
        color: var(--theme-secondary);
      }

    }

    &.dashboard-danger{
      .icon-stat{
        background-color: var(--danger);
      }

      .stat-title, h2{
        color: var(--danger);
      }
    }

    &.dashboard-secondary{
      background-color: #383a53 !important;
      .icon-stat{
      }

      .stat-title, h2{
        color: var(--light) !important;
      }
    }

  }

}


.empty-progress-bar{

  width: 100%;
  background-color: var(--theme-secondary);
  border-radius: 8px;
  margin: 8px 0 11px 0;

  .progress{
    background: var(--primary);
    border-radius: 8px;
  }

}

.time-tab{
  position: relative;
  z-index: 9;
  padding: 9px 25px;
  zoom: 0.8;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  border-radius: 100px;
  border: 3px solid white;
  margin-left: 7px;
  &:hover{
    border-color: #ddd;
  }
  &.selected{
    cursor: default;
    border-color: var(--theme-secondary-darker) !important;
  }
}



app-activity-graph{
  width: 100%;
}

.search-control{
  padding: 11px 20px;
  border: 2px solid  var(--theme-secondary);
  border-radius: 18px;
  color: var(--theme-secondary);
  font-size: 15px;
  font-weight: 600;
  margin-left: 10px;
  display: flex;
  align-items: flex-end;
  cursor:pointer;
  i{
    margin-left: 10px;
    color: var(--theme-secondary);
  }
  &:hover{
    background-color: #f5f5f5;
  }
  &.active-control{
    background-color: var(--theme-secondary) !important;
    color:white !important;
    i{
      color:white !important;
    }
  }
}


/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";

.theme-green .bs-datepicker-head{
  background-color: var(--theme-secondary) !important;
}

.theme-green bs-days-calendar-view:last-of-type {

  .bs-datepicker-head {
    border-top-right-radius: 9px;
    border-bottom-right-radius: 9px;
  }
  .bs-datepicker-body{
    border-bottom-right-radius: 9px;
  }

}

.theme-green bs-days-calendar-view:first-of-type {
  .bs-datepicker-head{
    border-top-left-radius: 9px;
    border-bottom-left-radius: 9px;
  }
  .bs-datepicker-body{
    border-bottom-left-radius: 9px;
  }
}

.theme-green .bs-datepicker-body table td.week span{
  color: var(--primary) !important;
}

.theme-green .bs-datepicker-body table td span.selected, .theme-green .bs-datepicker-body table td.selected span, .theme-green .bs-datepicker-body table td span[class*=select-]:after, .theme-green .bs-datepicker-body table td[class*=select-] span:after{
  background-color: var(--primary) !important;
}

.bs-datepicker{
  border-radius: 9px;
  box-shadow: none;
}

.bs-datepicker-container{
  padding: 0;
}

.bs-datepicker-body table.days span{
  font-weight: 600;
}

.bs-datepicker-head button{
  font-weight: 500;
}

.text-right{
  text-align: right;
}

.cdk-overlay-container:has(.qr-pop){
  .cdk-overlay-backdrop{
    background-color: var(--theme-secondary) !important;
    opacity: 0.4;
  }
}


.p-relative{
  position: relative;
}
